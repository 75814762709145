import { useMutation } from '@tanstack/react-query'
import { useSeller } from 'contexts'
import { UpdateVehicleReserveParams, currentQueryService } from 'utils/api/queries'

type UpdateVehicleReserveHookParams = Omit<UpdateVehicleReserveParams, 'cardCodeFamille'>

const useUpdateVehicleReserve = (params: UpdateVehicleReserveHookParams) => {
  const { currentSeller } = useSeller()
  const completedParams: UpdateVehicleReserveParams = {
    ...params,
    cardCodeFamille: currentSeller.cardCodeFamille,
  }

  return useMutation({
    mutationKey: ['updateVehicleReserve', completedParams.vehicleProject],
    mutationFn: () => currentQueryService.updateVehicleReserve(completedParams),
  })
}

export { useUpdateVehicleReserve }
