import React from 'react'
import {
  CalendarMonthOutlined,
  GarageOutlined,
  PendingActionsOutlined,
  QueryStatsOutlined,
  DirectionsCarFilled,
  History,
} from '@mui/icons-material'
import { PATHS } from './paths'

const PAGES = {
  futureSales: {
    icon: <CalendarMonthOutlined />,
    text: 'Mes ventes futures',
    link: PATHS.futureSales,
  },
  waitingFiles: {
    icon: <PendingActionsOutlined />,
    text: 'Dossiers en attente',
    link: PATHS.waitingFiles,
  },
  salesResult: {
    icon: <QueryStatsOutlined />,
    text: 'Mes résultats des ventes',
    link: PATHS.salesResult,
  },
  stock: {
    icon: <GarageOutlined />,
    text: 'Stock',
    link: PATHS.stock,
  },
  search: {
    icon: <DirectionsCarFilled />,
    text: 'Rechercher',
    link: PATHS.search,
  },
  history: {
    icon: <History />,
    text: 'Historique des réserves',
    link: PATHS.history,
  },
} as const

type Pages = typeof PAGES
type PagesKeys = keyof typeof PAGES
type Page = (typeof PAGES)[PagesKeys]
type PagesTexts = Page['text']

export { PAGES }
export type { Pages, PagesKeys, Page, PagesTexts }
