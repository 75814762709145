import { GridLocaleText } from '@mui/x-data-grid'
import { MuiTablePaginationLocalizedProps } from '@mui/x-data-grid/models/api/gridLocaleTextApi'

type LabelDisplayedRowsProps = {
  from: number
  to: number
  count: number
}

const frenchLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsProps) => {
  return `${from}–${to} à ${count !== -1 ? count : `plus de ${to}`}`
}

export const FRENCH_GRID_LOCALE_TEXT: GridLocaleText = {
  // Root
  'noRowsLabel': 'Aucune ligne',
  'noResultsOverlayLabel': 'Aucun résultat trouvé.',

  // Density selector toolbar button text
  'toolbarDensity': 'Densité',
  'toolbarDensityLabel': 'Densité',
  'toolbarDensityCompact': 'Compacte',
  'toolbarDensityStandard': 'Standard',
  'toolbarDensityComfortable': 'Confortable',

  // Columns selector toolbar button text
  'toolbarColumns': 'Colonnes',
  'toolbarColumnsLabel': 'Sélectionner des colonnes',

  // Filters toolbar button text
  'toolbarFilters': 'Filtres',
  'toolbarFiltersLabel': 'Afficher les filtres',
  'toolbarFiltersTooltipHide': 'Masquer les filtres',
  'toolbarFiltersTooltipShow': 'Afficher les filtres',
  'toolbarFiltersTooltipActive': (count) =>
    count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,

  // Quick filter toolbar field
  'toolbarQuickFilterPlaceholder': 'Recherche…',
  'toolbarQuickFilterLabel': 'Recherche',
  'toolbarQuickFilterDeleteIconLabel': 'Effacer',

  // Export selector toolbar button text
  'toolbarExport': 'Exporter',
  'toolbarExportLabel': 'Exporter',
  'toolbarExportCSV': 'Télécharger en CSV',
  'toolbarExportPrint': 'Imprimer',
  'toolbarExportExcel': 'Télécharger en Excel',

  // Columns management text
  'columnsManagementSearchTitle': 'Rechercher',
  'columnsManagementNoColumns': 'Aucune colonne',
  'columnsManagementShowHideAllText': 'Afficher/Masquer tout',
  'columnsManagementReset': 'Réinitialiser',
  'columnsManagementDeleteIconLabel': 'Effacer',

  // Filter panel text
  'filterPanelAddFilter': 'Ajouter un filtre',
  'filterPanelRemoveAll': 'Tout supprimer',
  'filterPanelDeleteIconLabel': 'Supprimer',
  'filterPanelLogicOperator': 'Opérateur logique',
  'filterPanelOperator': 'Opérateur',
  'filterPanelOperatorAnd': 'Et',
  'filterPanelOperatorOr': 'Ou',
  'filterPanelColumns': 'Colonnes',
  'filterPanelInputLabel': 'Valeur',
  'filterPanelInputPlaceholder': 'Valeur du filtre',

  // Filter operators text
  'filterOperatorContains': 'contient',
  'filterOperatorDoesNotContain': 'ne contient pas',
  'filterOperatorEquals': 'égal à',
  'filterOperatorDoesNotEqual': 'différent de',
  'filterOperatorStartsWith': 'commence par',
  'filterOperatorEndsWith': 'se termine par',
  'filterOperatorIs': 'est',
  'filterOperatorNot': "n'est pas",
  'filterOperatorAfter': 'est après',
  'filterOperatorOnOrAfter': 'est le ou après',
  'filterOperatorBefore': 'est avant',
  'filterOperatorOnOrBefore': 'est le ou avant',
  'filterOperatorIsEmpty': 'est vide',
  'filterOperatorIsNotEmpty': "n'est pas vide",
  'filterOperatorIsAnyOf': "est l'un des",
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Header filter operators text
  'headerFilterOperatorContains': 'Contient',
  'headerFilterOperatorDoesNotContain': 'Ne contient pas',
  'headerFilterOperatorEquals': 'Égal à',
  'headerFilterOperatorDoesNotEqual': 'Différent de',
  'headerFilterOperatorStartsWith': 'Commence par',
  'headerFilterOperatorEndsWith': 'Se termine par',
  'headerFilterOperatorIs': 'Est',
  'headerFilterOperatorNot': "N'est pas",
  'headerFilterOperatorAfter': 'Est après',
  'headerFilterOperatorOnOrAfter': 'Est le ou après',
  'headerFilterOperatorBefore': 'Est avant',
  'headerFilterOperatorOnOrBefore': 'Est le ou avant',
  'headerFilterOperatorIsEmpty': 'Est vide',
  'headerFilterOperatorIsNotEmpty': "N'est pas vide",
  'headerFilterOperatorIsAnyOf': "Est l'un des",
  'headerFilterOperator=': 'Égal à',
  'headerFilterOperator!=': 'Différent de',
  'headerFilterOperator>': 'Plus grand que',
  'headerFilterOperator>=': 'Supérieur ou égal à',
  'headerFilterOperator<': 'Moins que',
  'headerFilterOperator<=': 'Inférieur ou égal à',

  // Filter values text
  'filterValueAny': "n'importe quel",
  'filterValueTrue': 'vrai',
  'filterValueFalse': 'faux',

  // Column menu text
  'columnMenuLabel': 'Menu',
  'columnMenuShowColumns': 'Afficher les colonnes',
  'columnMenuManageColumns': 'Gérer les colonnes',
  'columnMenuFilter': 'Filtrer',
  'columnMenuHideColumn': 'Masquer la colonne',
  'columnMenuUnsort': 'Désactiver le tri',
  'columnMenuSortAsc': 'Trier par ASC',
  'columnMenuSortDesc': 'Trier par DESC',

  // Column header text
  'columnHeaderFiltersTooltipActive': (count) =>
    count !== 1 ? `${count} filtres actifs` : `${count} filtre actif`,
  'columnHeaderFiltersLabel': 'Afficher les filtres',
  'columnHeaderSortIconLabel': 'Trier',

  // Rows selected footer text
  'footerRowSelected': (count) =>
    count !== 1
      ? `${count.toLocaleString()} lignes sélectionnées`
      : `${count.toLocaleString()} ligne sélectionnée`,

  // Total row amount footer text
  'footerTotalRows': 'Total des lignes :',

  // Total visible row amount footer text
  'footerTotalVisibleRows': (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} sur ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  'checkboxSelectionHeaderName': 'Sélection par case à cocher',
  'checkboxSelectionSelectAllRows': 'Sélectionner toutes les lignes',
  'checkboxSelectionUnselectAllRows': 'Désélectionner toutes les lignes',
  'checkboxSelectionSelectRow': 'Sélectionner la ligne',
  'checkboxSelectionUnselectRow': 'Désélectionner la ligne',

  // Boolean cell text
  'booleanCellTrueLabel': 'oui',
  'booleanCellFalseLabel': 'non',

  // Actions cell more text
  'actionsCellMore': 'plus',

  // Column pinning text
  'pinToLeft': 'Épingler à gauche',
  'pinToRight': 'Épingler à droite',
  'unpin': 'Désépingler',

  // Tree Data
  'treeDataGroupingHeaderName': 'Grouper',
  'treeDataExpand': 'voir les enfants',
  'treeDataCollapse': 'masquer les enfants',

  // Grouping columns
  'groupingColumnHeaderName': 'Grouper',
  'groupColumn': (name) => `Grouper par ${name}`,
  'unGroupColumn': (name) => `Arrêter le regroupement par ${name}`,

  // Master/detail
  'detailPanelToggle': 'Basculer le panneau de détails',
  'expandDetailPanel': 'Étendre',
  'collapseDetailPanel': 'Réduire',

  // Used core components translation keys
  'MuiTablePagination': { frenchLabelDisplayedRows } as MuiTablePaginationLocalizedProps,

  // Row reordering text
  'rowReorderingHeaderName': 'Réorganisation des lignes',

  // Aggregation
  'aggregationMenuItemHeader': 'Agrégation',
  'aggregationFunctionLabelSum': 'somme',
  'aggregationFunctionLabelAvg': 'moyenne',
  'aggregationFunctionLabelMin': 'min',
  'aggregationFunctionLabelMax': 'max',
  'aggregationFunctionLabelSize': 'taille',
}
