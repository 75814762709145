import React from 'react'
import { useAlert } from 'contexts'
import { VehicleSheetDTO } from 'models/vehicleSheet'
import { DataTable } from 'components/dataTable'
import { useLoading, useVehicleModal } from 'utils/hooks'
import { useGetVehicleByImmat } from 'utils/hooks/queries/useGetVehicleByImmat'
import { searchByImmatLabels } from 'utils/labels'
import { ImmatSearchInput } from 'components'
import { normaliseImmat } from 'utils/helpers/format'

const SearchPage = () => {
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()
  const { setOpenModal, VehicleModal } = useVehicleModal()

  const [immatToSearch, setImmatToSearch] = React.useState<string>('')
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const {
    data: vehicleData,
    isLoading,
    error: getError,
    refetch,
  } = useGetVehicleByImmat({ vehicleImmat: immatToSearch })

  const hasVehicleData = vehicleData && vehicleData?.length !== 0

  const handleSearch = (immat: string) => {
    setImmatToSearch(normaliseImmat(immat))
  }

  React.useEffect(() => {
    if (immatToSearch) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [immatToSearch])

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert("Erreur lors de la recherche, veuillez vérifier l'immatriculation", 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  if (isLoading) {
    return (
      <>
        <ImmatSearchInput onSearch={handleSearch} />
        <Loading />
      </>
    )
  }

  if (immatToSearch.length === 0) {
    return <ImmatSearchInput onSearch={handleSearch} />
  }

  return (
    <>
      <ImmatSearchInput onSearch={handleSearch} />

      {hasVehicleData ? (
        <>
          <DataTable
            tableTitle={`Résultats de recherche pour "${immatToSearch}"`}
            columnsTitles={searchByImmatLabels}
            data={vehicleData as Array<VehicleSheetDTO>}
            options={{
              onRowClick: (line) => {
                const typedLine = line as unknown as VehicleSheetDTO
                setVehicleModalActiveId(typedLine.project)
                setOpenModal(true)
              },
              disableDownload: true,
            }}
          />
          <VehicleModal
            id={vehicleModalActiveId}
            onClose={() => setVehicleModalActiveId('')}
          />
        </>
      ) : (
        <p>
          Pas de résultat à afficher pour &ldquo;
          <span className="font-bold">{immatToSearch}&ldquo; </span>
          (pensez à vérifier les espaces et/ou tirets).{' '}
        </p>
      )}
    </>
  )
}

export default SearchPage
