import { createTheme } from '@mui/material'
import { frFR } from '@mui/material/locale'
import { GridToolbar } from '@mui/x-data-grid'
import React from 'react'
import { historyGridSizing } from 'utils/constants'
import { theme } from 'utils/theme'

const DataGridCustomToolbar = () => {
  return (
    <>
      <div
        className="flex items-center justify-between max-w-4xl px-4 pt-2 pb-2 rounded-tl-2xl rounded-tr-2xl rounded-top "
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText,
          height: historyGridSizing.titleHeight,
        }}
      >
        <h1 className="max-w-2xl pr-2 text-base">Historique des modifications de réserve</h1>
        <GridToolbar />
      </div>
      <div
        className="absolute w-full border border-gray-300 "
        style={{
          top: historyGridSizing.titleHeight,
          height: historyGridSizing.borderBoxHeight,
        }}
      >
        {' '}
      </div>
    </>
  )
}

export default DataGridCustomToolbar
