import React from 'react'
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import { useReserveQueue } from 'contexts'

type ReserveInputProps = {
  project: string
  value: number
}

const ReserveInput = ({ project, value }: ReserveInputProps) => {
  const { addTask, deleteTask, getTask, getLastSuccessfulTask, successfulTasks, queue } =
    useReserveQueue()

  const currentTask = getTask(project)

  const lastSuccessfulTask = getLastSuccessfulTask(project)

  const oldReserve = lastSuccessfulTask?.reserve || value

  const initialReserve = () => {
    if (currentTask) {
      return currentTask?.newReserve
    }
    if (lastSuccessfulTask) {
      return lastSuccessfulTask.reserve
    }
    return oldReserve
  }

  const [newReserve, setNewReserve] = React.useState(initialReserve)
  React.useEffect(() => {
    setNewReserve(initialReserve)
  }, [queue, successfulTasks])

  const [cancelButtonClicked, setCancelButtonClicked] = React.useState(false)

  const isTaskNewOrPending = ['new', 'pending'].includes(currentTask?.status || '')
  const isTaskError = currentTask?.status === 'error'
  const isNewReserveSameAsOld = newReserve === oldReserve
  const isNewReserveNumberInvalid = [NaN, undefined].includes(newReserve)

  const disableButtons = isNewReserveNumberInvalid || isNewReserveSameAsOld || isTaskNewOrPending

  // Update state on input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputReserve = event.target.valueAsNumber
    setNewReserve(inputReserve)
  }

  const resetInput = () => {
    setNewReserve(oldReserve)
    if (currentTask) {
      deleteTask(project)
    }
  }

  const applyForQueue = () => {
    if (isNewReserveSameAsOld) return
    if (isNewReserveNumberInvalid) {
      resetInput()
      return
    }
    addTask(project, newReserve, oldReserve)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      applyForQueue()
    }
    if (event.key === 'Escape') {
      resetInput()
    }
  }
  const handleCancelClick = () => {
    setCancelButtonClicked(true)
    resetInput()
  }

  const handleBlur = () => {
    if (!cancelButtonClicked) {
      applyForQueue()
    } else {
      resetInput()
    }
    setCancelButtonClicked(false)
  }

  const inputClasses = (() => {
    if (isTaskNewOrPending) {
      return 'border border-gray-500 bg-gray-200 text-gray-400'
    }
    if (isTaskError) {
      return 'border border-red-600 border-2 bg-red-200 text-red-800 font-bold'
    }
    return 'border border-orange-500 bg-orange-200'
  })()

  React.useEffect(() => {
    if (currentTask?.status === 'error' && isNewReserveSameAsOld) deleteTask(project)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newReserve])

  return (
    <div
      onBlur={handleBlur}
      className={`${inputClasses} flex items-center justify-between rounded-sm cursor-default w-40 focus-within:border-2`}
    >
      <input
        id={`reserveInput-vehicleProject-${project}`}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={newReserve > -1 ? newReserve : ''}
        type="number"
        min={0}
        disabled={isTaskNewOrPending}
        className="p-1 w-full border bg-inherit border-none outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      />
      <div className="flex justify-end ">
        {!disableButtons ? (
          <>
            <CheckCircleOutlined
              fontSize="large"
              color="success"
              className="cursor-pointer"
              onClick={() => {
                applyForQueue()
              }}
            />
            <CancelOutlined
              fontSize="large"
              className="text-red-800 cursor-pointer"
              onMouseDown={handleCancelClick}
            />
          </>
        ) : (
          <>
            <CheckCircleOutlined
              fontSize="large"
              className="text-gray-400"
            />
            <CancelOutlined
              fontSize="large"
              className="text-gray-400"
            />
          </>
        )}
        {/* Additional Error Icon */}
        {isTaskError && (
          <ErrorIcon
            fontSize="large"
            className="text-red-800 "
          />
        )}
      </div>
    </div>
  )
}

export default ReserveInput
