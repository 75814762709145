import { useQuery } from '@tanstack/react-query'
import { useSeller } from 'contexts'
import { HistoryDTO } from 'models'
import React from 'react'
import { currentQueryService } from 'utils/api/queries'

const useGetHistory = () => {
  const { currentSeller, isFamilyAllSellersOptionSelected } = useSeller()
  const dateOrderParam = '&orderBy=date&orderDirection=desc'

  const cardCodeParam = isFamilyAllSellersOptionSelected
    ? `cardCodeFamille=${currentSeller.cardCodeFamille}`
    : `cardCode=${currentSeller.cardCode}`

  // todo: type history
  const query = useQuery<Array<HistoryDTO>, Error>({
    queryKey: ['getHistory', currentSeller.cardName],
    queryFn: () => currentQueryService.getHistory(`${cardCodeParam}${dateOrderParam}`),
    enabled: false,
  })

  const { refetch } = query

  React.useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSeller, isFamilyAllSellersOptionSelected])

  return query
}

export { useGetHistory }
