import React from 'react'

type EmptyParagraphProps = {
  customSentence?: string
}

const EmptyDataParagraph: React.FC<EmptyParagraphProps> = ({ customSentence }) => {
  const defaultSentence = "Il n'y a pas de données à afficher"

  const content = customSentence || defaultSentence

  return <p>{content}</p>
}

export default EmptyDataParagraph
