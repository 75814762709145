import { SellerDTO } from 'models'

const dummySeller: Array<SellerDTO> = [
  {
    metadata: {
      type: 'vpasvc.ODataServices.extranet.VendeursType',
      uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vendeurs('ABC0003')",
    },
    cardCode: 'ABC0003',
    cardName: 'ABCC CREDIT BAIL',
    u_W3C_SFEI: '99AFBDF5F03BD25DC12570D600535299',
    u_W3C_ORIG: null,
    cardCodeFamille: 'ABC0298',
    cardNameFamille: 'ABCC(Famille)',
    u_W3C_SFEI_Famille: null,
    inactif: '',
  },
]

const dummySellers: Array<SellerDTO> = [
  dummySeller[0],
  {
    metadata: {
      type: 'vpasvc.ODataServices.extranet.VendeursType',
      uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vendeurs('AAA0000')",
    },
    cardCode: 'AAA0001',
    cardName: 'A1 CARD NAME',
    u_W3C_SFEI: '00AFBDF5F03BD25DC12570D600535200',
    u_W3C_ORIG: null,
    cardCodeFamille: 'FAA0000',
    cardNameFamille: 'A Family (Famille)',
    u_W3C_SFEI_Famille: null,
    inactif: '',
  },
  {
    metadata: {
      type: 'vpasvc.ODataServices.extranet.VendeursType',
      uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vendeurs('AAA0000')",
    },
    cardCode: 'AAA0002',
    cardName: 'A2 CARD NAME',
    u_W3C_SFEI: '00AFBDF5F03BD25DC12570D600535200',
    u_W3C_ORIG: null,
    cardCodeFamille: 'FAA0000',
    cardNameFamille: 'A Family (Famille)',
    u_W3C_SFEI_Famille: null,
    inactif: 'Y',
  },
  {
    metadata: {
      type: 'vpasvc.ODataServices.extranet.VendeursType',
      uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vendeurs('AAA0000')",
    },
    cardCode: 'AAA0003',
    cardName: 'A3 CARD NAME',
    u_W3C_SFEI: '00AFBDF5F03BD25DC12570D600535200',
    u_W3C_ORIG: null,
    cardCodeFamille: 'FAA0000',
    cardNameFamille: 'A Family (Famille)',
    u_W3C_SFEI_Famille: null,
    inactif: '',
  },
  {
    metadata: {
      type: 'vpasvc.ODataServices.extranet.VendeursType',
      uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vendeurs('BBB0000')",
    },
    cardCode: 'BBB0001',
    cardName: 'B1 CARD NAME',
    u_W3C_SFEI: '11AFBDF5F03BD25DC12570D600535222',
    u_W3C_ORIG: null,
    cardCodeFamille: 'FBB0000',
    cardNameFamille: 'B Family (Famille)',
    u_W3C_SFEI_Famille: null,
    inactif: '',
  },
  {
    metadata: {
      type: 'vpasvc.ODataServices.extranet.VendeursType',
      uri: "https://hanaservices.vpauto.fr:4300/vpasvc/ODataServices/extranet.xsodata/Vendeurs('BBB0000')",
    },
    cardCode: 'BBB0002',
    cardName: 'B2 CARD NAME',
    u_W3C_SFEI: '11AFBDF5F03BD25DC12570D600535222',
    u_W3C_ORIG: null,
    cardCodeFamille: 'FBB0000',
    cardNameFamille: 'B Family (Famille)',
    u_W3C_SFEI_Famille: null,
    inactif: '',
  },
]

// Filter dummySellers with unique cardCodeFamille
const dummySellerFamiliesCardCode = new Set<string>()
const dummySellerFamilies = dummySellers.filter((seller) => {
  if (dummySellerFamiliesCardCode.has(seller.cardCodeFamille)) {
    return false
  }
  dummySellerFamiliesCardCode.add(seller.cardCodeFamille)
  return true
})

export { dummySeller, dummySellers, dummySellerFamilies }
