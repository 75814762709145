import React from 'react'

type ProcessedStringProps = {
  string: string
}

const ProcessedString: React.FC<ProcessedStringProps> = ({ string }) => {
  const chopedString = string.replace(/\(\w{2}, \d{2}\/\d{2}\/\d{4}\)/g, '$&\n')

  const allLines = chopedString.split('\n')

  return (
    <div>
      {allLines.map((line) => (
        <React.Fragment key={line}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  )
}

export default ProcessedString
