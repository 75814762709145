const PATHS = {
  home: '/',

  signIn: 'sign-in',
  authorizationCallback: 'authorization/callback',

  futureSales: 'future-sales',
  futureSalesDetails: 'future-sales/:saleId',

  waitingFiles: 'waiting-files',

  salesResult: 'sales-result',
  salesResultDetails: 'sales-result/:saleId',
  history: 'history',

  stock: 'stock',
  search: 'search',
} as const

type PathsKeys = keyof typeof PATHS
type Paths = (typeof PATHS)[PathsKeys]

export { PATHS }
export type { PathsKeys, Paths }
