const normaliseImmat = (entryImmat: string) => {
  let normalised = entryImmat
    .replace(/[-\s]+/g, ' ')
    .toUpperCase()
    .trim()

  normalised = normalised.replace(/([A-Z]+)(?=[0-9])|([0-9]+)(?=[A-Z])/g, '$& ')
  return normalised.trim()
}

export { normaliseImmat }
