import React from 'react'

import HistoryDataGrid from 'components/historyDataGrid'
import DataTableToolbar from 'components/dataTable/DataTableToolbar'

const HistoryPage = () => {
  return <HistoryDataGrid />
}

export default HistoryPage
