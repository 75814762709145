import React from 'react'
import { CgNotes } from 'react-icons/cg'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Button, { ButtonProps } from '@mui/material/Button'
import ProcessedString from 'components/processString/ProcessedString'

// original
type ObservationPopUpProps = ButtonProps & {
  observationString: string
  customButtonStyle?: object
}

// MUI

const ObservationPopUp = ({
  observationString,
  customButtonStyle,
  ...otherProps
}: ObservationPopUpProps) => {
  // Original
  const observation = observationString
  const defaultButtonStyle = {
    fontSize: '2rem',
    display: 'flex',
    width: '100%',
    height: '100%',
    background: 'transparent',
    boxShadow: 'none',
    color: 'black',
  }

  const mergedStyle = customButtonStyle
    ? { ...defaultButtonStyle, ...customButtonStyle }
    : defaultButtonStyle

  // MUI
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        style={mergedStyle}
        tabIndex={-1}
        {...otherProps}
      >
        <CgNotes />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: { width: '450px', overflowY: 'auto' },
          },
        }}
      >
        {/* <Typography sx={{ p: 2 }}>{observation}</Typography> */}
        <Typography sx={{ p: 2 }}>
          <ProcessedString string={observation} />
        </Typography>
      </Popover>
    </>
  )
}

export default ObservationPopUp
