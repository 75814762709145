import React from 'react'
import { futureSaleVehiclesTableLabels } from 'utils/labels'
import { DataTable } from 'components/dataTable'
import {
  FutureSaleVehicleDTO,
  FutureSaleVehicleOverload,
  defaultFutureSaleVehicleDTO,
} from 'models'
import { useGetFutureSaleVehicles } from 'utils/hooks/queries'
import { FutureSaleVehiclesContext, useAlert, useFutureSale } from 'contexts'
import { useCheckData, useLoading, useVehicleModal } from 'utils/hooks'
import { generateSalesTableTitle } from 'utils/helpers'

const FutureSaleVehiclesPage = () => {
  const { futureSale } = useFutureSale()

  const {
    data: futureSaleVehiclesData,
    isLoading,
    error: getError,
    isSuccess,
  } = useGetFutureSaleVehicles({
    saleId: futureSale.docEntryVente?.toString() as string,
    isSaleOnline: futureSale.typeVente === 'Electronique',
  })

  const { Loading } = useLoading()
  const { displayAlert } = useAlert()
  const { setOpenModal, VehicleModal } = useVehicleModal()
  const { dataAfterCheck, isDataEmpty } = useCheckData<FutureSaleVehicleDTO>(
    futureSaleVehiclesData,
    defaultFutureSaleVehicleDTO,
  )

  const [futureSaleVehicles, setFutureSaleVehicles] = React.useState<Array<FutureSaleVehicleDTO>>([
    defaultFutureSaleVehicleDTO,
  ])
  const [vehicleModalActiveId, setVehicleModalActiveId] = React.useState<string>('')

  const formattedFutureSaleVehicles: Array<FutureSaleVehicleOverload> = futureSaleVehicles.map(
    (vehicle) => {
      const {
        estimation: vehicleEstimation,
        coteKm: vehicleCoteKm,
        cotePerso: vehicleCotePerso,
      } = vehicle

      const hasEstimation = Boolean(vehicleEstimation)
      const canComputePercentageCP = hasEstimation && Boolean(vehicleCotePerso)
      const canComputePercentageCK = hasEstimation && Boolean(vehicleCoteKm)

      const percentCP = canComputePercentageCP
        ? `${Math.round(
            ((vehicleEstimation as number) / (vehicleCotePerso as number)) * 100,
          ).toString()}%`
        : null
      const percentCK = canComputePercentageCK
        ? `${Math.round(
            ((vehicleEstimation as number) / (vehicleCoteKm as number)) * 100,
          ).toString()}%`
        : null

      return {
        ...vehicle,
        percentCP,
        percentCK,
      } as FutureSaleVehicleOverload
    },
  )

  React.useEffect(() => {
    if (getError?.message) {
      displayAlert('Erreur lors du chargement des véhicules de la vente future', 'error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError])

  React.useEffect(() => {
    if (isSuccess) {
      setFutureSaleVehicles(dataAfterCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  if (isLoading) return <Loading />
  if (isDataEmpty) {
    return (
      <p>
        Les données ne peuvent pas être affichées, veuillez contacter{' '}
        <a
          href="mailto:support@vpauto.zendesk.com"
          className="underline underline-offset-4"
        >
          support@vpauto.zendesk.com
        </a>
      </p>
    )
  }

  return (
    <FutureSaleVehiclesContext.Provider value={formattedFutureSaleVehicles}>
      <DataTable
        tableTitle={generateSalesTableTitle(futureSale)}
        columnsTitles={futureSaleVehiclesTableLabels}
        data={formattedFutureSaleVehicles}
        options={{
          onRowClick: (line) => {
            const typedLine = line as unknown as FutureSaleVehicleDTO
            setVehicleModalActiveId(typedLine.project)
            setOpenModal(true)
          },

          enableUpload: true,
          keysNotToSort: ['observationsVendeur', 'memoExterne'],
        }}
      />

      <VehicleModal
        id={vehicleModalActiveId}
        onClose={() => setVehicleModalActiveId('')}
      />
    </FutureSaleVehiclesContext.Provider>
  )
}

export default FutureSaleVehiclesPage
