import EmptyDataParagraph from 'components/common/EmptyDataParagraph'
import FetchErrorParagraph from 'components/common/FetchErrorParagraph'
import { useAlert } from 'contexts'
import React from 'react'
import { useLoading } from 'utils/hooks'
import { useGetHistory } from 'utils/hooks/queries/useGetHistory'
import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { HistoryDTO } from 'models'
import { formatDate } from 'utils/helpers/format'
import { frFR } from '@mui/material/locale'
import { createTheme, ThemeProvider } from '@mui/material'
import { theme } from 'utils/theme'
import { historyGridSizing } from 'utils/constants'
import DataGridCustomToolbar from './DataGridCustomToolbar'
import { FRENCH_GRID_LOCALE_TEXT } from './frenchDataGridLocale'

const HistoryDataGrid = () => {
  const { data, isLoading, isError, error } = useGetHistory()
  const { displayAlert } = useAlert()
  const { Loading } = useLoading()

  const frenchTheme = createTheme(
    {
      palette: {
        primary: theme.palette.warning,
        secondary: theme.palette.primary,
      },
    },
    frFR,
  )

  type ColProp = {
    translation: string
    width?: number
    flex?: number
  }
  type HistoryLabels = Partial<Record<keyof HistoryDTO, ColProp>>

  const labels: HistoryLabels = {
    numFolder: { translation: 'N° dossier', width: 100 },
    immatriculation: { translation: 'Immat.', width: 100 },
    userId: { translation: 'Id utilisateur', width: 310 },
    updateDateTime: { translation: 'Date', width: 160 },
    reservePrice: { translation: 'Réserve', width: 120 },
    name: { translation: 'Utilisateur', flex: 0.6 },
    email: { translation: 'Email', flex: 1 },
    remoteIpAdress: { translation: 'Adresse IP', width: 120 },
  }

  const columns: GridColDef[] = Object.keys(labels).map((label) => {
    const typedLabel = label as keyof typeof labels
    const labelConfig = labels[typedLabel]

    if (!labelConfig) {
      throw new Error(`Label configuration for '${typedLabel}' is missing.`)
    }

    const newCol: GridColDef = {
      field: label,
      headerName: labelConfig.translation,
      sortable: true,
      width: labelConfig.width,
      flex: labelConfig.flex,
      headerClassName: 'bg-secondary-main text-white',
    }
    return newCol
  })

  if (isLoading) {
    return <Loading />
  }
  if (isError) {
    displayAlert(error.message, 'error')
    return <FetchErrorParagraph />
  }
  if (!data || data.length === 0) {
    return <EmptyDataParagraph />
  }
  const rows = data.map((history, index) => {
    const formatedDate = formatDate(history.updateDateTime, true)
    return {
      ...history,
      updateDateTime: formatedDate,
      id: index,
      reservePrice: `${history.reservePrice}.00€`,
    }
  })

  return data.length === 0 ? (
    <EmptyDataParagraph />
  ) : (
    <ThemeProvider theme={frenchTheme}>
      <Box sx={{ height: historyGridSizing.boxHeight, width: historyGridSizing.boxWidth }}>
        <DataGrid
          columns={columns}
          rows={rows}
          slots={{ toolbar: DataGridCustomToolbar }}
          sx={{ border: 'none' }}
          pageSizeOptions={[5, 10, 25, 100, { value: -1, label: 'Toutes' }]}
          localeText={FRENCH_GRID_LOCALE_TEXT}
        />
      </Box>
    </ThemeProvider>
  )
}

export default HistoryDataGrid
