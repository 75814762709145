import React from 'react'
import { theme } from 'utils/theme'

type DataTableToolbarProps = {
  title: string
  downLoadButton?: React.JSX.Element
  upLoadButton?: React.JSX.Element
}

const DataTableToolbar = ({ title, downLoadButton, upLoadButton }: DataTableToolbarProps) => {
  const displayedTitle = title
  const displayedDownLoadButton = downLoadButton
  const displayedUpLoadButton = upLoadButton
  return (
    <div
      className={`flex flex-row justify-between ${
        upLoadButton ? 'max-w-6xl' : 'max-w-3xl'
      }   px-4 pt-2 pb-2 text-dark rounded-tl-2xl rounded-tr-2xl rounded-top`}
      style={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
      }}
    >
      <h1 className="h-full max-w-2xl pr-2">{displayedTitle}</h1>
      <div className="flex items-center justify-between ">
        {' '}
        {displayedDownLoadButton}
        {displayedUpLoadButton}
      </div>
    </div>
  )
}

export default DataTableToolbar
