import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  formatDate,
  formatDateFlipADateString,
  formatTableDataToCSV,
  isDateKeyToFormat,
} from 'utils/helpers/format'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { DataTables, DataTableProps, DateISO8601, FutureSaleVehicleOverload } from 'models'
import { theme } from 'utils/theme'
import { ObservationPopUp } from 'components/common'
import { PATHS } from 'utils/constants'
import { DataTableDownLoadButton } from '../DataTableDownLoadButton'
import { DataTableUploadButton } from '../DataTableUploadButton'
import DataTableToolbar from '../DataTableToolbar'
import ReserveInput from '../ReserveInput'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    wordWrap: 'break-word',
    maxWidth: '150px',
    textAlign: 'center',
    border: '1px solid black',
    cursor: 'pointer',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    outline: `2px solid ${theme.palette.secondary.main}`,
  },
  '&:disabled': {
    outline: 'none',
  },
}))

const DataTable = <T extends DataTables>({
  columnsTitles,
  tableTitle,
  data,
  options,
}: DataTableProps<T>) => {
  const isFutureSaleVehicle = window.location.toString().includes(`${PATHS.futureSales}/`)
  const columnsTitlesKeys = Object.keys(columnsTitles) as unknown as Array<keyof T>
  const [sortKey, setSortKey] = React.useState<keyof T | null>(null)
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc')

  const handleSort = (key: keyof T) => {
    if (sortKey !== key) {
      setSortKey(key)
      setSortOrder('asc')
      return
    }
    if (sortOrder === 'desc') {
      setSortKey(null)
      return
    }
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  }

  const stringOrNumber = (string: string | number) => {
    // returns number if string starts with a number, else returns string
    if (typeof string === 'string' && string[2] !== '/' && !Number.isNaN(parseInt(string, 10))) {
      return parseInt(string, 10)
    }
    return string
  }

  const adjustSortingValue = (key: string, value: string | number | null) => {
    const sortingValue = value ?? ''
    if (key.startsWith('date')) {
      if (key === 'dateVenteFR') {
        return formatDateFlipADateString(sortingValue.toString())
      }
      return sortingValue
    }
    return stringOrNumber(sortingValue as string | number)
  }

  const sortedData = data.slice().sort((a, b) => {
    if (sortKey) {
      const aValue = adjustSortingValue(sortKey as string, a[sortKey] as string | number | null)
      const bValue = adjustSortingValue(sortKey as string, b[sortKey] as string | number | null)

      if (aValue === bValue) {
        return 0
      }

      if (sortOrder === 'asc') {
        return aValue < bValue ? -1 : 1
      }
      return aValue > bValue ? -1 : 1
    }

    return 0
  })

  const currentDate = new Date().toLocaleDateString()
  const csvFileName = `VpAuto_Extranet_${currentDate}_${tableTitle}.csv`
  const dataConvertedToCSV = formatTableDataToCSV({ columnsTitles, data: sortedData })
  const otherClickKeys = ['observationsVendeur', 'memoExterne']

  const renderTableCell = (line: T, key: keyof T, isFutureSaleVehicleReserve: boolean) => {
    if (key === 'observationsVendeur' || key === 'memoExterne')
      return (
        <ObservationPopUp
          observationString={line[key] ? (line[key] as string) : ''}
          customButtonStyle={{
            ...(line[key] ? {} : { color: theme.palette.grey, cursor: 'default' }),
          }}
          disabled={!line[key]}
        />
      )
    if (isFutureSaleVehicleReserve) {
      const futureSaleVehicle = line as FutureSaleVehicleOverload
      return (
        <ReserveInput
          key={futureSaleVehicle.project}
          project={futureSaleVehicle.project as string}
          value={futureSaleVehicle.reserve as number}
        />
      )
    }
    if (!line[key]) return '-'
    if (isDateKeyToFormat(key as string)) {
      return formatDate(line[key] as DateISO8601)
    }
    return line[key] as string | number | null
  }

  return (
    <>
      <DataTableToolbar
        title={tableTitle}
        downLoadButton={
          options?.disableDownload ? undefined : (
            <DataTableDownLoadButton
              csvData={dataConvertedToCSV}
              fileName={csvFileName}
            />
          )
        }
        upLoadButton={options?.enableUpload ? <DataTableUploadButton /> : undefined}
      />

      <TableContainer
        component={Paper}
        sx={{ marginBottom: '32px' }}
      >
        <Table
          sx={{ minWidth: 900 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {columnsTitlesKeys.map((key) => {
                if (!columnsTitles[key]) return null
                return (
                  <StyledTableCell
                    align="left"
                    key={key as string}
                    onClick={() => {
                      if (options?.keysNotToSort?.includes(key as string)) {
                        return
                      }
                      handleSort(key as keyof T)
                    }}
                    className="select-none"
                  >
                    {columnsTitles[key]}
                    {(() => {
                      if (sortKey !== key) return null
                      return sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                    })()}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((line) => (
              <StyledTableRow
                key={data.indexOf(line)}
                sx={{
                  cursor: 'pointer',
                }}
              >
                {columnsTitlesKeys.map((key) => {
                  const isFutureSaleVehicleAndReserveKey: boolean =
                    isFutureSaleVehicle && key === 'reserve'
                  const isClickDifferent = otherClickKeys.includes(key.toString() as string)
                  if (columnsTitles[key])
                    return (
                      <StyledTableCell
                        key={key.toString()}
                        align="center"
                        sx={key === 'observationsVendeur' ? { padding: '0px' } : null}
                        onClick={() => {
                          if (isFutureSaleVehicleAndReserveKey) {
                            return null
                          }
                          if (isClickDifferent) {
                            return null
                          }
                          return options?.onRowClick(line)
                        }}
                      >
                        {renderTableCell(line, key, isFutureSaleVehicleAndReserveKey)}
                      </StyledTableCell>
                    )
                  return null
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default DataTable
export type { DataTableProps }
