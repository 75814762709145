import dayjs from 'dayjs'
import {
  FutureSaleVehicleDTO,
  SaleResultVehicleDTO,
  StockVehicleDTO,
  VehicleSheetDTO,
  WaitingFileVehicleDTO,
} from 'models'

const dateKeysToFormat: Array<
  | keyof Partial<FutureSaleVehicleDTO>
  | keyof Partial<SaleResultVehicleDTO>
  | keyof Partial<StockVehicleDTO>
  | keyof Partial<WaitingFileVehicleDTO>
  | keyof Partial<VehicleSheetDTO>
> = [
  'date1MEC',
  'dateCreation',
  'dateLivraison',
  'dateMission',
  'dateSortie',
  'dateVenteAffectee',
  'dateVenteAffecteeE',
  'dateDerniereCotation',
]

const isDateKeyToFormat = (key: string) => {
  return (dateKeysToFormat as Array<string>).includes(key)
}

const formatDate = (date: string, moment?: boolean): string => {
  const format = moment ? 'DD/MM/YY, hh:mm:ss' : 'DD/MM/YY'
  return dayjs(date).format(format)
}

const formatDatesInObject = <T extends Record<string, unknown>, R extends Record<string, unknown>>(
  obj: T,
): R => {
  const dateKeys: Array<keyof Partial<T>> = Object.keys(obj).filter((objKey) =>
    isDateKeyToFormat(objKey),
  )
  const onlyObjFormattedDates = dateKeys.reduce((prev, dateKey) => {
    const currentDate = obj[dateKey]

    if (currentDate === null) return { ...prev, [dateKey]: '' }
    if (currentDate === '0') return { ...prev, [dateKey]: '' }
    const date = formatDate(currentDate as string)
    return { ...prev, [dateKey]: date }
  }, {}) as Record<keyof Partial<T>, string>

  const objFormattedDates = { ...obj, ...onlyObjFormattedDates } as R

  return objFormattedDates
}

const dateFormats = {
  usString: /^\d{4}\/\d{2}\/\d{2}$/,
  frStringLong: /^\d{2}\/\d{2}\/\d{4}$/,
  frStringShort: /^\d{2}\/\d{2}\/\d{2}$/,
} as const

const isDateCorrectlyFormatted = (dateString: string, formats: (keyof typeof dateFormats)[]) => {
  return formats.some((format) => {
    const regex = new RegExp(dateFormats[format])
    return regex.test(dateString)
  })
}

const formatDateShortenYearFR = (dateString: string): string => {
  if (!isDateCorrectlyFormatted(dateString, ['frStringLong', 'frStringShort'])) {
    return ''
  }
  const parts = dateString.toString().split('/')
  const shortYear = parts[2].slice(-2)
  // Reorder the parts to DD/MM/YY format
  const convertedDate = `${parts[0]}/${parts[1]}/${shortYear}`
  return convertedDate
}

const formatDateFlipADateString = (dateString: string): string => {
  if (!isDateCorrectlyFormatted(dateString, ['frStringLong', 'frStringShort', 'usString'])) {
    return ''
  }
  const parts = dateString.split('/')
  // Reorder the parts to DD/MM/YYYY format
  const convertedDate = `${parts[2]}/${parts[1]}/${parts[0]}`
  return convertedDate
}

export {
  isDateKeyToFormat,
  formatDate,
  formatDatesInObject,
  formatDateFlipADateString,
  formatDateShortenYearFR,
}
