const palette = {
  primary: {
    main: '#E7521F',
  },

  secondary: {
    main: '#7F7F7F',
    light: '#D0CECE',
    dark: '#404040',
    contrastText: '#FFF',
  },
  warning: {
    main: '#FFF',
  },

  success: {
    main: '#3DB037',
    contrastText: '#FFF',
  },
  error: {
    main: '#E36E5D',
    contrastText: '#FFF',
  },
}

export { palette }
