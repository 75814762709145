import React from 'react'

const FetchErrorParagraph = () => {
  return (
    <p>
      Les données ne peuvent pas être affichées, veuillez contacter{' '}
      <a
        href="mailto:support@vpauto.zendesk.com"
        className="underline underline-offset-4"
      >
        support@vpauto.zendesk.com
      </a>
    </p>
  )
}

export default FetchErrorParagraph
